import  Navbar from "./components/navbar";
import B from "./components/body";
import Bar from "./components/bar";
import Nepale from "./components/slider";
export default function App() {
  return (
    <>
    <div className="bg-[#6ca772]">
      <Navbar />
      <B />
      <Bar />
      <Nepale />
      </div>
   </>
  );
}
